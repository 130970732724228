.css3slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden; }

.css3slider-item {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-delay: 0s; }
  .css3slider-item > img {
    display: none; }
  .trans-fade .css3slider-item.active {
    opacity: 1; }
  .trans-accordeon .css3slider-item {
    opacity: 1; }
    .trans-accordeon .css3slider-item:first-child {
      transform: translateX(0%); }
  .trans-ltr .css3slider-item,
  .trans-rtl .css3slider-item,
  .trans-btt .css3slider-item,
  .trans-ttb .css3slider-item {
    opacity: 1; }

.slide:before {
  display: none !important; }

.slide .slide-content {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  bottom: 1.5em;
  left: 0.5em;
  border: solid 2px #999;
  padding: 1.5em 3em;
  overflow: hidden; }
  .trans-accordeon .slide .slide-content {
    -webkit-box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.25);
    -o-box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.25);
    box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.25); }

.bandeaux-slider {
  position: relative;
  width: 100%;
  height: 20em;
  overflow: hidden;
  background-color: #EEE;
  margin-bottom: 1em; }
  .bandeaux-slider > ul {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0; }
  .bandeaux-slider .css3slider-next,
  .bandeaux-slider .css3slider-prev {
    display: none; }
  .bandeaux-slider .navigator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    padding: 0;
    height: 1.5em;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center; }
    .bandeaux-slider .navigator .controleBar-item {
      display: block;
      float: left;
      text-indent: -9999px;
      position: relative;
      font-size: 2em;
      width: 1em;
      height: 1em;
      margin: 0.125em;
      background-color: lightgray;
      border: solid 0.125em #919191;
      border-radius: 0.25em; }
      .bandeaux-slider .navigator .controleBar-item:hover {
        background-color: #b2b2b2;
        border-color: #222222; }
      .bandeaux-slider .navigator .controleBar-item:before {
        content: '';
        position: absolute;
        display: block;
        background-color: #222222;
        width: 0.5em;
        height: 0.5em;
        margin: 0.125em;
        border-radius: 0.125em;
        transform: scale(0);
        transition: transform 0.25s linear; }
      .bandeaux-slider .navigator .controleBar-item.active:before {
        transform: scale(1); }
      .no-touch .bandeaux-slider .navigator .controleBar-item {
        font-size: 1em; }
  .bandeaux-slider:nth-of-type(3) .navigator {
    flex-direction: row-reverse; }
    .bandeaux-slider:nth-of-type(3) .navigator .controleBar-item {
      float: right; }
  .bandeaux-slider:nth-of-type(3) .css3slider-next {
    right: auto;
    left: 0;
    border-radius: 0 0.5em 0.5em 0; }
    .bandeaux-slider:nth-of-type(3) .css3slider-next:before {
      content: '\2039'; }
  .bandeaux-slider:nth-of-type(3) .css3slider-prev {
    left: auto;
    right: 0;
    border-radius: 0.5em 0 0 0.5em; }
    .bandeaux-slider:nth-of-type(3) .css3slider-prev:before {
      content: '\203A'; }
  .bandeaux-slider:nth-of-type(5) .navigator, .bandeaux-slider:nth-of-type(6) .navigator {
    right: auto;
    top: 0;
    height: auto;
    width: 1.5em; }
  .bandeaux-slider:nth-of-type(5) .slide .slide-content, .bandeaux-slider:nth-of-type(6) .slide .slide-content {
    bottom: 0.5em;
    left: 1.5em; }
  .bandeaux-slider:nth-of-type(5) .css3slider-next,
  .bandeaux-slider:nth-of-type(5) .css3slider-prev, .bandeaux-slider:nth-of-type(6) .css3slider-next,
  .bandeaux-slider:nth-of-type(6) .css3slider-prev {
    position: absolute;
    top: 50%;
    top: auto;
    left: 50%;
    margin-top: 0;
    margin-left: -1.5em; }
  .bandeaux-slider:nth-of-type(5) .navigator {
    flex-direction: column; }
  .bandeaux-slider:nth-of-type(5) .css3slider-next,
  .bandeaux-slider:nth-of-type(5) .css3slider-prev {
    transform: rotate(90deg); }
  .bandeaux-slider:nth-of-type(5) .css3slider-next {
    right: auto;
    bottom: 0; }
  .bandeaux-slider:nth-of-type(5) .css3slider-prev {
    top: 0; }
  .bandeaux-slider:nth-of-type(6) .navigator {
    flex-direction: column-reverse; }
  .bandeaux-slider:nth-of-type(6) .css3slider-next,
  .bandeaux-slider:nth-of-type(6) .css3slider-prev {
    transform: rotate(-90deg); }
  .bandeaux-slider:nth-of-type(6) .css3slider-next {
    right: auto;
    top: 0; }
  .bandeaux-slider:nth-of-type(6) .css3slider-prev {
    bottom: 0; }

.bandeaux {
  position: relative;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  margin-top: -3.2rem; }
  @media only print, only screen and (min-width: 48.063em) {
    .bandeaux {
      width: calc(100% - 4em); } }
  .bandeaux:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 50%; }

.bandeaux-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  border: 3px solid #222222;
  border-right: none;
  border-left: none;
  background-color: #222222; }
  .bandeaux-slider:after {
    display: block;
    content: '';
    clear: both; }
  .bandeaux-slider .navigator {
    display: none; }
  @media only print, only screen and (min-width: 48.063em) {
    .bandeaux-slider {
      border: 3px solid #222222; }
      .bandeaux-slider .navigator {
        display: flex;
        height: 2.5em;
        top: 0.5em;
        right: 1em;
        bottom: auto;
        justify-content: flex-end; }
        .bandeaux-slider .navigator .controleBar-item {
          border-color: #222222;
          border-radius: 0.5em;
          background-color: #222222; }
          .bandeaux-slider .navigator .controleBar-item:before {
            border-radius: 0.25em;
            background-color: #fff; }
          .bandeaux-slider .navigator .controleBar-item.active {
            background-color: #fff; } }
